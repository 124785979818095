export const priseData = [
  {
    id: "Электрика",
    name: "Электрика",
    description:
      "Установка и ремонт розеток и выключателей, светильников и люстр, проводки, электрощитов",
    price: "От 15р",
  },
  {
    id: "Сантехника",
    name: "Сантехника",
    description:
      "Установка и ремонт кранов, смесителей, унитазов, раковин, водонагревателей, труб, прочистка засоров",
    price: "От 25р",
  },
  {
    id: "Мебель",
    name: "Мебель",
    description:
      "Сборка мебели, ремонт стульев, столов, диванов, шкафов, замена фурнитуры.",
    price: "От 35р",
  },
  {
    id: "Окна",
    name: "Окна и двери",
    description:
      "Ремонт и регулировка окон, дверей, замена ручек, петель, уплотнителей, ремонт и замена замков ",
    price: "От 35р",
  },
  {
    id: "Монтаж",
    name: "Монтаж",
    description:
      "Монтаж кронштейнов,телевизоров, вешалок, карнизов, навесных полок, зеркал, картин,жалюзи, ролет",
    price: "От 25р",
  },
  {
    id: "Ремонт",
    name: "Ремонт и покраска",
    description:
      "Покраска стен, потолков, дверей, окон, ремонт штукатурки, шпаклевание, замена напольных покрытий.",
    price: "От 35р",
  },
  {
    id: "Демонтаж",
    name: "Демонтаж",
    description:
      "Демонтаж различных покрытий,перегородок,стен. Уборка и вывоз мусора.",
    price: "От 50р",
  },
  {
    id: "Ремонтиустановка",
    name: "Ремонт и установка бытовой техники",
    description:
      "Телевизоры, стиральные и посудомоечные машины,плиты и духовые шкафы, кондиционеры.",
    price: "От 30р",
  },
];
